<template>
  <v-card flat :loading="loading" :disabled="loading">
    <v-list dense nav class="menu_dashboard">
      <v-list-item-group color="info">
        <div v-for="(pai, index1) in menus" :key="index1">
          <v-list-item
            v-if="pai.type === 'single' && pai.permission"
            :to="pai.no_action ? '' : { path: pai.path }"
            :disabled="pai.disabled"
            @click="pai.click_menu ? mudaMenu(pai.click_menu) : ''"
          >
            <v-list-item-icon>
              <v-icon>{{ pai.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ pai.name }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group
            v-else-if="pai.type === 'group' && pai.permission"
            :group="pai.path"
            no-action
            :prepend-icon="pai.icon"
            :disabled="pai.disabled"
          >
            <template v-slot:activator>
              <v-list-item-title>{{ pai.name }}</v-list-item-title>
            </template>

            <div v-for="(child, index2) in pai.children" :key="index2">
              <v-list-item
                v-if="child.type === 'single' && child.permission"
                :to="child.no_action ? '' : { path: child.path }"
                :disabled="child.disabled"
                class="pl-6"
              >
                <v-list-item-icon>
                  <v-icon>{{ child.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ child.name }}</v-list-item-title>
              </v-list-item>
              <v-list-group
                v-else-if="child.type === 'group' && child.permission"
                :disabled="child.disabled"
                :prepend-icon="child.icon"
                :group="child.path"
                sub-group
              >
                <template v-slot:activator>
                  <v-list-item-title>{{ child.name }}</v-list-item-title>
                </template>
                <div v-for="(child2, index3) in child.children" :key="index3">
                  <v-list-item
                    :to="child2.no_action ? '' : { path: child2.path }"
                    :disabled="child2.disabled"
                    class="pl-5"
                  >
                    <v-list-item-icon>
                      <v-icon>{{ child2.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ child2.name }}</v-list-item-title>
                  </v-list-item>
                </div>
              </v-list-group>
            </div>
          </v-list-group>
        </div>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "MenuDashboard",

  data() {
    return {
      loading: false,
      menus: null,
    };
  },

  computed: {},

  methods: {
    ...mapActions("Componentes", {
      STORE_getAccess: "getAccess",
    }),

    mudaMenu(menu) {
      this.$store.commit("UPDATE_MENUDINAMICO", menu);
    },

    async permissionComponent(component, type) {
      let permissao = await this.STORE_getAccess({
        componente: component,
        type: type,
      });

      return permissao;
    },
  },

  async mounted() {
    this.loading = true;
    this.menus = [
      {
        type: "single",
        name: "Dashboard",
        path: "/gestor/dashboard",
        icon: "mdi-view-dashboard",
        disabled: false,
        permission: await this.permissionComponent("vueGestorDashboard", "menu"),
      },
      {
        type: "group",
        name: "Dados",
        icon: "mdi-database",
        path: "/gestor/dados",
        disabled: false,
        permission: await this.permissionComponent("vueGestorDados", "menu"),
        children: [
          {
            type: "single",
            name: "Empresa",
            path: "/gestor/dados/empresa",
            icon: "mdi-domain",
            disabled: false,
            permission: await this.permissionComponent("vueGestorDadosEmpresa", "menu"),
          },

          {
            type: "single",
            name: "Equipe",
            path: "/gestor/dados/equipe",
            icon: "mdi-badge-account-horizontal",
            disabled: false,
            permission: await this.permissionComponent("vueGestorDadosEquipe", "menu"),
          },
          {
            type: "single",
            name: "Cargos",
            path: "/gestor/dados/cargos",
            icon: "mdi-badge-account-horizontal-outline",
            disabled: false,
            permission: await this.permissionComponent("vueGestorDadosCargos", "menu"),
          },
          {
            type: "single",
            name: "Serviços Prestados",
            path: "/gestor/dados/servicos-prestados",
            icon: "mdi-playlist-check",
            disabled: false,
            permission: await this.permissionComponent(
              "vueGestorDadosServicosPrestados",
              "menu"
            ),
          },
        ],
      },
      {
        type: "group",
        name: "Formulários de Trabalho",
        icon: "mdi-form-select",
        path: "/gestor/formulario-trabalho",
        disabled: false,
        permission: await this.permissionComponent(
          "vueGestorFormulariosTrabalho",
          "menu"
        ),
        children: [
          {
            type: "single",
            name: "Novo Job",
            path: "/gestor/formulario-trabalho/atividades/lista-atividades",
            icon: "mdi-toolbox-outline",
            disabled: false,
            permission: await this.permissionComponent(
              "vueGestorFormulariosTrabalhoNovoJob",
              "menu"
            ),
          },
          // {
          //   type: "group",
          //   name: "Briefings",
          //   icon: "mdi-briefcase-edit-outline",
          //   disabled: false,
          //   path: "/gestor/formulario-trabalho/briefings",
          //   children: [
          //     {
          //       type: "single",
          //       name: "Briefing",
          //       path: "/gestor/formulario-trabalho/briefings/lista-briefing",
          //       icon: "mdi-circle-medium",
          //       disabled: true,
          //     },
          //   ],
          // },
          // {
          //   type: "group",
          //   name: "Planejamentos",
          //   path: "/gestor/formulario-trabalho/planejamentos",
          //   icon: "mdi-notebook-outline",
          //   disabled: false,
          // },
          {
            type: "group",
            name: "Mídias Sociais",
            path: "/gestor/formulario-trabalho/midias-sociais",
            icon: "mdi-facebook",
            disabled: false,
            permission: await this.permissionComponent(
              "vueGestorFormulariosTrabalhoMidiasSociais",
              "menu"
            ),
            children: [
              {
                type: "single",
                name: "Calendário Editorial Mídias Sociais",
                path:
                  "/gestor/formulario-trabalho/midias-sociais/calendario-midias-sociais",
                icon: "mdi-circle-medium",
                disabled: false,
              },
              // {
              //   type: "single",
              //   name: "Cronograma Mídias Sociais",
              //   path:
              //     "/gestor/formulario-trabalho/midias-sociais/cronograma-midias-sociais",
              //   icon: "mdi-circle-medium",
              //   disabled: false,
              // },
            ],
          },
          // {
          //   type: "group",
          //   name: "WEB",
          //   path: "/gestor/formulario-trabalho/web",
          //   icon: "mdi-facebook-workplace",
          //   disabled: false,
          // },
        ],
      },

      {
        type: "single",
        name: "Clientes",
        path: "/gestor/clientes",
        icon: "mdi-account-group",
        disabled: false,
        permission: await this.permissionComponent("vueGestorClientes", "menu"),
      },
      // {
      //   type: "group",
      //   name: "Fornecedores",
      //   // path: "/gestor/fornecedores",
      //   icon: "mdi-camera-front-variant",
      // },

      {
        type: "group",
        name: "Relatorios",
        path: "/gestor/relatorios",
        icon: "mdi-file-chart",
        disabled: false,
        permission: await this.permissionComponent("vueGestorRelatorios", "menu"),
        children: [
          {
            type: "single",
            name: "Atividades",
            path: "/gestor/relatorios/atividade",
            icon: "mdi-circle-medium",
            disabled: false,
            permission: await this.permissionComponent(
              "vueGestorRelatoriosAtividades",
              "menu"
            ),
          },
          {
            type: "single",
            name: "Atividades-Colaborador",
            path: "/gestor/relatorios/atividade-colaborador",
            icon: "mdi-circle-medium",
            disabled: false,
            permission: await this.permissionComponent(
              "vueGestorRelatoriosAtividadesColaborador",
              "menu"
            ),
          },
          {
            type: "single",
            name: "Atividades-Cliente",
            path: "/gestor/relatorios/atividade-cliente",
            icon: "mdi-circle-medium",
            disabled: false,
            permission: await this.permissionComponent(
              "vueGestorRelatoriosAtividadesCliente",
              "menu"
            ),
          },
          {
            type: "single",
            name: "Aniversários Equipes",
            path: "/gestor/relatorios/aniversario-equipe-cliente",
            icon: "mdi-circle-medium",
            disabled: false,
            permission: await this.permissionComponent(
              "vueGestorRelatoriosAniversariosEquipeClientes",
              "menu"
            ),
          },
          // {
          //   type: "single",
          //   name: "Desempenho",
          //   path: "/gestor/relatorios/desempenho",
          //   icon: "mdi-circle-medium",
          //   disabled: true,
          // },
        ],
      },
    ];
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
.menu_dashboard ::v-deep .v-list-item {
  padding-top: 3px;
  padding-bottom: 3px;
  margin-bottom: 3px;
}

.menu_dashboard ::v-deep .v-list-item .v-list-item__title {
  font-size: 0.9rem !important;
  font-weight: 500;
  line-height: 1rem;
}
</style>
